@import "../../_globalColor";

.payment-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
