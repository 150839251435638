@import "../../_globalColor";

.apps-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
  display: flex; flex-direction: column;
}
.download { width: 100%; display: inline-block;}
.tab{background: none; float: left; margin: 0 10px }
.tabButton{padding:10px; text-decoration-color: #555;}
.tabButton:active{text-decoration-color: #f2f2f2;}
.tabButtonActive{text-decoration-color: #f2f2f2;}
