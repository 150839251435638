@import "../../_globalColor";

.cover {
  padding: 0 !important;
  width: 100vw;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  // backgroundImage: `url(${bg})`,
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.cover::after {
  background-color: rgba(0, 0, 0, 0.49);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.cover h1, .cover p{
  color: $lightBackground1;
  position: relative;
  z-index: 1;
}